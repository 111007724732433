* {
  font-family: "Campton";
}
:focus {
  outline: none;
}
body {
  margin: 0;
}

@font-face {
  font-family: 'Campton';
  src: url("../fonts/campton/campton-bold-webfont.woff2") format('woff2'),
       url("../fonts/campton/campton-bold-webfont.woff") format('woff'),
       url("../fonts/campton/campton-bold-webfont.ttf") format('truetype');
  font-weight: 900; 
}

@font-face {
  font-family: 'Campton';
  src: url("../fonts/campton/campton-book-webfont.woff2") format('woff2'),
       url("../fonts/campton/campton-book-webfont.woff") format('woff'),
       url("../fonts/campton/campton-book-webfont.ttf") format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Campton';
  src: url("../fonts/campton/campton-light-webfont.woff2") format('woff2'),
       url("../fonts/campton/campton-light-webfont.woff") format('woff'),
       url("../fonts/campton/campton-light-webfont.ttf") format('truetype');
  font-weight: light;
}

@font-face {
  font-family: 'Campton';
  src: url("../fonts/campton/campton-semibold-webfont.woff") format('woff'),
       url("../fonts/campton/campton-semibold-webfont.ttf") format('truetype');
  font-weight: bold; 
}