* {
  font-family: "Campton";
}
:focus {
  outline: none;
}
body {
  margin: 0;
}

@font-face {
  font-family: 'Campton';
  src: url(/static/media/campton-bold-webfont.d51dacbe.woff2) format('woff2'),
       url(/static/media/campton-bold-webfont.09d7d919.woff) format('woff'),
       url(/static/media/campton-bold-webfont.2b549e69.ttf) format('truetype');
  font-weight: 900; 
}

@font-face {
  font-family: 'Campton';
  src: url(/static/media/campton-book-webfont.f3f7b3ad.woff2) format('woff2'),
       url(/static/media/campton-book-webfont.fd063b37.woff) format('woff'),
       url(/static/media/campton-book-webfont.8e64f7fd.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Campton';
  src: url(/static/media/campton-light-webfont.4f958b6b.woff2) format('woff2'),
       url(/static/media/campton-light-webfont.05d20dc9.woff) format('woff'),
       url(/static/media/campton-light-webfont.05b367a9.ttf) format('truetype');
  font-weight: light;
}

@font-face {
  font-family: 'Campton';
  src: url(/static/media/campton-semibold-webfont.08ead56b.woff) format('woff'),
       url(/static/media/campton-semibold-webfont.52852295.ttf) format('truetype');
  font-weight: bold; 
}
